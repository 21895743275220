import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import homeCook from '../images/homeCook.jpg';
import dineOut from '../images/dineOut.jpg';
import orderIn from '../images/orderIn.jpg';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class feeding extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           disabled:true,
           loader:false
 
        }
    this.submitFeedingChoice = this.submitFeedingChoice.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
         this.submitFeedingChoice()
      }


      async submitFeedingChoice(){ 



        $('.feeding1').click(async function(){ 
          
           
            this.setState({ 
                laoder:true
            })

            let score = parseInt($('.feeding1').val())

            const feedingChoiceData = { 
                feedingScore  : score
            }
    
            const pushFeedingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeFeedingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(feedingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
           
                 this.setState({ 
                    disabled: false,
                    laoder:false
                })
    $('.feedingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))





       

        $('.feeding2').click(async function(){ 
          
            this.setState({ 
                loader:true
            })

            let score = parseInt($('.feeding2').val())
    
            const feedingChoiceData = { 
                feedingScore  : score
            }
    
            const pushFeedingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeFeedingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(feedingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
       
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
           $('.feedingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))



        $('.feeding3').click(async function(){ 
   
            this.setState({ 
              
                loader:true
            })
            let score = parseInt($('.feeding3').val())

            const feedingChoiceData = { 
                feedingScore  : score
            }
    
            const pushFeedingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeFeedingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(feedingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
                
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
           $('.feedingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))
 
      }

      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Feeding </Badge>

 <h3 className="header-font blue-text"> 
 Which best describes your eating habits?
 </h3>                 
</div>

<div className="col-12 col-md-4 answerPan text-center"> 
    <figure id="1">
    <img src={dineOut} width="100%" /> 

    <input inline 
          className="feeding1"
          type="radio"
           name="feeding"
           value="5"
       />


    <figcaption> 
        <h4 className="header-font blue-text"> Dine-out </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="3">
    <img src={homeCook}width="100%" /> 


    <input inline 
          className="feeding2"
          type="radio"
           name="feeding"
           value="1"
       />

    <figcaption> 
        <h4 className="header-font blue-text"> Home Cooked   </h4>
    </figcaption>
    </figure>
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="5">
    <img src={orderIn}  width="100%" /> 

    <input inline 
          className="feeding3"
          type="radio"
           name="feeding"
           value="3"
       />


    <figcaption> 
        <h4 className="header-font blue-text"> Order-in </h4>
    </figcaption>
    </figure>
</div>
<Link to="/Padi/setup/lifestyle/choices/leisure" className="feedingContinue standard-margin-bottom">

         <Button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font" disabled={this.state.disabled}> Continue  </Button>     

                                      </Link>    

</div>


<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default feeding