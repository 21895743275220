import React, {Component} from 'react';
import $ from 'jquery';
import Nav from './navigationB.js'
import Button from 'react-bootstrap/Button';
import padiCircle from '../images/padi-circle.png';
import transportation from '../components/transportation.js';
import feeding from '../components/feeding.js';
import healthcare from '../components/healthcare.js';
import housing from '../components/housing.js';
import utility from '../components/utility.js';
import leisure from '../components/leisure.js';
import age from '../components/age.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


class formB extends Component { 


    constructor(props){
        super(props)
        this.state={

           firstname:""
 
        }

        this.retrieveUsername = this.retrieveUsername.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.retrieveUsername()

      }


    async retrieveUsername(){


        const pullFirstname= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/getfirstname', { 
      method:"POST",
      headers:{
        "content-type":"application/JSON"
      },
      mode:"cors",
      credentials:'include' 
    })

  .then(response => response.json())
  .then(response => {  

    console.log(response)
    this.setState({ 

        firstname: response.firstname
    })

  })
            
    }

      

      render(){
       return(
   
        <> 
        <Nav />
        <div className="row standard-padding-rl grey-bg full-width mOff"> 
        <div className="col-12 col-md-2 standard-margin-top"> 
          <img className="standard-margin-top img-fluid padi-width" src={padiCircle} />
         </div>
         <div className="col-12 col-md-10 standard-margin-bottom standard-margin-top-padi-text">
                <h3 className="blue-text body-font spaced-text mobile-heading ">
                    Let's take the personality test {this.state.firstname}
                </h3>
                <hr className="white-bg mid-width divider-style" />
                <h3 className="blue-text body-font spaced-text mobile-heading">
                 select your  <span className="lime-text body-font white-text limeBox"> preferred lifestyle choice </span>
                </h3>
           
        </div>

        </div>


        <div className="standard-padding-rl full-width"> 

                 <Route path="/Padi/setup/lifestyle/choices/transportation" component={transportation} />

                 <Route path="/Padi/setup/lifestyle/choices/feeding" component={feeding} />

                 <Route path="/Padi/setup/lifestyle/choices/healthcare" component={healthcare} />

                 <Route path="/Padi/setup/lifestyle/choices/leisure" component={leisure} />

                 <Route path="/Padi/setup/lifestyle/choices/housing" component={housing} />

                 <Route path="/Padi/setup/lifestyle/choices/utility" component={utility} />
        
        </div>


     </>

       );

      }

}

export default formB