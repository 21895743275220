import React, {Component} from 'react';
import $ from 'jquery';
import Nav from './navigationB.js'
import Button from 'react-bootstrap/Button';
import padiCircle from '../images/padi-circle.png';
import contribution from '../components/contributions.js';
import savings from '../components/savings.js';
import retirement from '../components/retirement.js';
import aspiration from '../components/aspiration.js';
import age from '../components/age.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


class form extends Component { 


    constructor(props){
        super(props)
        this.state={

           setup:false,
           firstname:""
 
        }
         
        this.retrieveUsername = this.retrieveUsername.bind(this)
      }
 

      
        componentDidMount(){
          window.scrollTo(0, 0)
          this.retrieveUsername()
  
        }
     


    async retrieveUsername(){


          const pullFirstname= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/getfirstname', { 
        method:"POST",
        headers:{
          "content-type":"application/JSON"
        },
        mode:"cors",
        credentials:'include' 
      })
  
    .then(response => response.json())
    .then(response => {  
  
      console.log(response)
      this.setState({ 
  
          firstname: response.firstname
      })
  
    })
              
      }
      

      render(){
       return(
   
        <> 
        <Nav />
        <div className="row standard-padding-rl grey-bg full-width mOff"> 
        <div className="col-12 col-md-2 standard-margin-top"> 
          <img className="standard-margin-top img-fluid padi-width" src={padiCircle} />
         </div>
         <div className="col-12 col-md-10 standard-margin-bottom standard-margin-top-padi-text">
                <h3 className="blue-text body-font spaced-text mobile-heading ">
                    I'm going to need some information {this.state.firstname}
                </h3>
                <hr className="white-bg mid-width divider-style" />
                <h3 className="blue-text body-font spaced-text mobile-heading">
                 about your  <span className="lime-text body-font white-text limeBox"> Pension savings </span>
                </h3>
           
        </div>

        </div>


        <div className="standard-padding-rl full-width"> 

                 <Route path="/Padi/setup/financial-information/contribution" component={contribution} />

                 <Route path="/Padi/setup/financial-information/savings" component={savings} />


                 <Route path="/Padi/setup/financial-information/aspiration" component={aspiration} />


                 <Route path="/Padi/setup/financial-information/retirement" component={retirement} />

                 <Route path="/Padi/setup/financial-information/birth-day" component={age} />
        
        </div>


     </>

       );

      }

}

export default form 