import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import aircon from '../images/aircon.jpg';
import openair from '../images/openair.jpg';
import roomtemp from '../images/roomtemp.jpg';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class utility extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           disabled:true
 
        }

        this.submitUtilityChoice = this.submitUtilityChoice.bind(this)
      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitUtilityChoice()
      }


      async submitUtilityChoice(){ 



        $('.utility1').click(async function(){ 
          
          this.setState({ 
            loader:true
        })

            let score = parseInt($('.utility1').val())
     
            const utilityChoiceData = { 
                utilityScore  : score
            }
    
            const pushHousingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeUtilityChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(utilityChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
        
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.utilityContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))




     
        $('.utility2').click(async function(){ 
          
          this.setState({ 
            loader:true
        })

            let score = parseInt($('.utility2').val())

            const utilityChoiceData = { 
                utilityScore  : score
            }
    
            const pushHousingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeUtilityChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(utilityChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.utilityContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))



        $('.utility3').click(async function(){ 
          
          this.setState({ 
            loader:true
        })

            let score = parseInt($('.utility3').val())
     
            const utilityChoiceData = { 
                utilityScore  : score
            }
    
            const pushHousingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeUtilityChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(utilityChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
             
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.utilityContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))


 
      }



      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Utility </Badge>

 <h3 className="header-font blue-text"> 
 What atmosphere best suits you when indoors?
 </h3>                 
</div>

<div className="col-12 col-md-4 answerPan text-center"> 
    <figure id="1">
    <img src={openair} width="100%" /> 

    <input inline 
                             className="utility1"
                             type="radio"
                             name="utility"
                             value="1"
                         

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Open air </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="3">
    <img src={aircon} width="100%" /> 


    <input inline 
                             className="utility2"
                             type="radio"
                             name="utility"
                             value="5"
                         

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Air conditioned </h4>
    </figcaption>
    </figure>
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="5">
    <img src={roomtemp} width="100%" /> 

    <input inline 
                             className="utility3"
                             type="radio"
                             name="utility"
                             value="3"
                         

                             />

    <figcaption> 
        <h4 className="header-font blue-text"> Room Temperature </h4>
    </figcaption>
    </figure>
</div>

<div className="text-center">

<a href="/Padi/setup/advisory/results">
<button variant="primary" className="col-10 white-text col-md-2 utilityContinue standard-margin-bottom pal-button-blue pal-lime-bg body-font" disabled={this.state.disabled}> See your results  </button>     

</a>

</div>

</div>

<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default utility