import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import home from './components/home.js';
import nav from './components/navigation.js';
import form from './components/form.js';
import formB from './components/formB.js';
import report from './components/report.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function App() {
  return (
    <Router>

       
    <Switch>
      <Route exact path="/" component={home} />
      <Route path='/Padi/setup/financial-information' component={form} />
      <Route path='/Padi/setup/lifestyle/choices' component={formB} />
      <Route path='/Padi/setup/advisory/results' component={report} />
    
     </Switch>
</Router>

  );
}

export default App;
