import react, {component} from "react";
import {Link} from 'react-router-dom';




const Footer = () =>{
    return(
        <>
            <div className="footer white-bg">
                <div className="container-fluid">
                    <div className="row mt-5 footer_row">
                        <div className="col-12 col-md-4 standard-margin-bottom">
                        <h5 className="header-font blue-text"> About our calculator </h5>
                            <p className="body-font blue-text"> PalCalc is a Retirement Personal Finance tool aimed at helping you achieve and sustain the lifestyle you
      aspire for at retirement.
                            </p>
                        </div>
                        <div className="col-12 col-md-3 footer2 standard-margin-bottom">
                            <ul className="pOff blue-text">
                            <li>
                            <h5 className="header-font blue-text "> Quick Links </h5>
                            </li>
                            <a href="https://www.palpensions.com/need-a-pal/" data_target="blank"  className="body-font blue-text">
                            <li> Transfer my RSA </li> 
                            </a>
                            <a href="https://www.palpensions.com/" data_target="blank" className="body-font blue-text">
                               <li> PAL Pensions Website </li>
                               </a>
                            </ul>
                        </div>
                        <div className="col-12 col-md-2 footer3 standard-margin-bottom">
                        <h5 className="header-font blue-text"> Social Media </h5>
                            <a href="https://www.instagram.com/palpensions/"><i className="fa fa-instagram blue-text largeFont standard-padding-rl"></i></a>
                            <a href="https://ng.linkedin.com/company/pal-pensions"><i className="fa fa-linkedin blue-text largeFont"></i></a>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-12 col-md-2 mOff"> 
                        <p className="body-font blue-text text-center">
                                (C) Pal Pensions PFA 2021
                             </p>
                          
                        </div>


                        <div className="col-12 offset-md-7 col-md-2"> 
                        <p className="body-font blue-text text-center">
                                Product by iNspire
                             </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;