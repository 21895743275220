import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import potSize from '../images/potSize.png';
import numeral from 'numeral';
import parsley from 'parsleyjs';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';



import $ from 'jquery';


class aspiration extends Component{ 


     
    constructor(props){
        super(props)
        this.state={
           setup:false,
           loader:false
        }

     this.submitAspiration = this.submitAspiration.bind(this)
     this.formatNumeral = this.formatNumeral.bind(this)
     this.submissionControl = this.submissionControl.bind(this)
      
    }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitAspiration()
        this.formatNumeral()
        this.submissionControl()

      }


      async submissionControl(){

        $('.aspiration').change(async function(){ 

           $('.aspirationContinue').fadeOut('slow', function(){ 
              $('.aspirationSubmission').fadeIn('slow')
           })

        })
   }



      async formatNumeral(){
        $('.aspiration').keyup(function(){ 
          let formatedValue = numeral($('.aspiration').val()).format(0,0)
          console.log(formatedValue)
          $('.aspiration').val(formatedValue)
         
          });
      }

      async submitAspiration(){ 

        $('.aspirationSubmission').click(async function(e){ 

            e.preventDefault()



            if($('.aspiration').val() === ""){
              return alert('Please add a value')
            }
         
             this.setState({
                loader:true
             })
           
            const aspirationData = { 
                 desiredPensionPot :numeral($('.aspiration').val()).value()
            }
    
            const pushAspiration= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/data/storeDesiredPensionPot', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(aspirationData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
      
                    this.setState({
                        loader:false
                    })


                 $('.aspirationSubmission').fadeOut('fast', function(){ 

                    $('.aspirationContinue').fadeIn('slow')
                 })
    
    
            })

        }.bind(this))

 
      }




      render(){
          return(

            <> 
                <div className="row standard-margin-top"> 

<div className="col-12 col-md-6 standard-padding-rl"> 

<Badge className="white-text blue-bg" bg="primary "> About your aspirations </Badge>

 <h3 className="header-font blue-text"> 
 What is your desired RSA balance at retirement? 
 </h3>
 <Form className="text-center standard-margin-top">
    <Form.Group className="mb-3" controlId="">  
         <Form.Control type="text" className="form-design-a  blue-text largeFont aspiration text-center" placeholder="1,000,000" />
            <Form.Text className="text-muted white-text body-font" >
               I'll use this information to estimate your required contribution
            </Form.Text>
    </Form.Group>
                       
    
    <Link to="/Padi/setup/financial-information/savings">
    <button onClick="" variant="primary" className="col-10 col-md-2 white-text pal-button-blue pal-lime-bg body-font"> Back </button>
    </Link>       
                             
    <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue pal-lime-bg body-font aspirationSubmission"> Save </button>

           <Link to="/Padi/setup/financial-information/retirement" className="aspirationContinue">
                                        
            <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue body-font"> Continue  </button>            
          </Link>       
         
</Form>

</div>


<div className="col-12 col-md-6"> 

      <img src={potSize} width="100%" /> 
</div>

</div>


<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default aspiration