import React, {Component} from 'react'; 
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import sharedApt from '../images/shared.jpg';
import privateApt from '../images/privateApt.jpg';
import ownedProp from '../images/ownedProp.jpg';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class housing extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           disabled:true,
           loader:false

 
        }

     this.submitHousingChoice = this.submitHousingChoice.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitHousingChoice()

      }

      async submitHousingChoice(){ 



        $('.housing1').click(async function(){ 
          
          this.setState({
            loader:true
          })

            let score = parseInt($('.housing1').val())
     
            const housingChoiceData = { 
                housingScore  : score
            }
    
            const pushHousingChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHousingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(housingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.housingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))





        $('.housing2').click(async function(){ 
          this.setState({
            loader:true
          })

            let score = parseInt($('.housing2').val())
    
            const housingChoiceData = { 
                housingScore  : score
            }
    
            const pushContribution= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHousingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(housingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
                
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.housingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))




        $('.housing3').click(async function(){ 
          
            this.setState({
              loader:true
            })

            let score = parseInt($('.housing3').val())
      
            const housingChoiceData = { 
                housingScore  : score
            }
    
            const pushContribution= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHousingChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(housingChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 

              

                 this.setState({ 
                     disabled: false,
                     loader:false
                 })
    $('.housingContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))

 
      }

      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Housing </Badge>

 <h3 className="header-font blue-text"> 
 Where will you live after retirement?
 </h3>                 
</div>

<div className="col-12 col-md-4 answerPan text-center" id="1"> 
    <figure id="1">
    <img src={sharedApt} width="100%" /> 


    <input inline 
                             className="housing1 click"
                             type="radio"
                             id="2"
                             name="housing"
                             label="Female"
                             value="1"
                             onChange={this.setHousingValue}

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Shared Apartment </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center" id="2"> 

<figure id="3">
    <img src={privateApt} width="100%" /> 


    <input inline 
                             className="housing2 click"
                             type="radio"
                             id="2"
                             name="housing"
                             label="Female"
                             value="3"

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Private Apartment </h4>
    </figcaption>
    </figure>
</div>

<div className="col-12 col-md-4 answerPan text-center" id="3"> 

<figure id="5">
    <img src={ownedProp} width="100%" /> 

    <input inline 
                             className="housing3 click"
                             type="radio"
                             id="2"
                             name="housing"
                             label="Female"
                             value="5"
                      
                            

                             />
                         
    <figcaption> 
        <h4 className="header-font blue-text"> Owned property </h4>
    </figcaption>
    </figure>
</div>


<Link to="/Padi/setup/lifestyle/choices/transportation" className="housingContinue standard-margin-bottom">

         <Button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font"> Continue  </Button>     

                                      </Link>    
            
</div>

<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default housing