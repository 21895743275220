import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import books from '../images/books.jpg';
import drinks from '../images/drinks.jpg';
import holiday from '../images/holiday.jpg';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class leisure extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           disabled:true,
           loader:false
 
        }

        this.submitLeisureChoice = this.submitLeisureChoice.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
          this.submitLeisureChoice()

      }



      async submitLeisureChoice(){ 



        $('.leisure1').click(async function(){ 
          
        
            this.setState({ 
                loader:true
            })

            let score = parseInt($('.leisure1').val())

            const leisureChoiceData = { 
                leisureScore  : score
            }
    
            const pushLeisureChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeLeisureChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(leisureChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
             
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.leisureContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))




        $('.leisure2').click(async function(){ 
          

            this.setState({ 
                loader:true
            })
            let score = parseInt($('.leisure2').val())

            const leisureChoiceData = { 
                leisureScore  : score
            }
    
            const pushLeisureChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeLeisureChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(leisureChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
         
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.leisureContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))





        $('.leisure3').click(async function(){ 
          
            this.setState({ 
                loader:true
            })

            let score = parseInt($('.leisure3').val())
 
            const leisureChoiceData = { 
                leisureScore  : score
            }
    
            const pushLeisureChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeLeisureChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(leisureChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
    $('.leisureContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))
 
      }


      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Leisure </Badge>

 <h3 className="header-font blue-text"> 
 Which image describes 'me' time for you?
 </h3>                 
</div>

<div className="col-12 col-md-4 answerPan text-center"> 
    <figure id="1">
    <img src={books} width="100%" /> 
    
    <input inline 
                             className="leisure1"
                             type="radio"
                             name="leisure"
                             value="1"
                         

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Books </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="3">
    <img src={holiday} width="100%" /> 

    <input inline 
                             className="leisure2"
                             type="radio"
                             name="leisure"
                             value="5"
                         

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Holiday escape  </h4>
    </figcaption>
    </figure>
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="5">
    <img src={drinks}  width="100%" /> 

    <input inline 
                             className="leisure3"
                             type="radio"
                             name="leisure"
                             value="3"
                         

                             />

    <figcaption> 
        <h4 className="header-font blue-text"> Drinks with Friends </h4>
    </figcaption>
    </figure>
</div>
<Link to="/Padi/setup/lifestyle/choices/utility" className="leisureContinue standard-margin-bottom">

         <Button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font" disabled={this.state.disabled}> Continue  </Button>     

    </Link>    
</div>

<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default leisure