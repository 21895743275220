import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Birthday from '../images/age.jpg';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class age extends Component{ 


     
    constructor(props){
        super(props)
        this.state={
           setup:false,
           loader:false
        }

        this.submitAge = this.submitAge.bind(this)
        this.submissionControl = this.submissionControl.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitAge()
        this.submissionControl()

      }


      async submissionControl(){

           $('.age').change(async function(){ 

              $('.ageContinue').fadeOut('slow', function(){ 
                 $('.ageSubmission').fadeIn('slow')
              })

           })
      }


      async submitAge(){ 

        $('.ageSubmission').click(async function(e){ 

          
            //compute Age value 
            
            
            e.preventDefault()

            if($('.age').val() === ""){
              return alert('Please add a value')
            }

            this.setState({
              loader:true
            })


          
     
     
              let date = moment($('.age').val()).format( "YYYY-MM-DD")
        
            const ageData = { 
                 dob : date
                
            }
    
            const pushDob= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/data/storeDOB', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(ageData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
             
              this.setState({
                loader:false
              })
                 $('.ageSubmission').fadeOut('fast', function(){ 

                    $('.ageContinue').fadeIn('slow')
                 })
    
    
            })

        }.bind(this))

 
      }


      render(){
          return(

            <> 
                <div className="row standard-margin-top"> 

<div className="col-12 col-md-6 standard-padding-rl"> 

<Badge className="white-text blue-bg" bg="primary "> For my information </Badge>

 <h3 className="header-font blue-text"> 
 When were you born ? 
 </h3>
 <Form className="text-center standard-margin-top">
    <Form.Group className="mb-3" controlId="">
         <Form.Control type="date" className="form-design-a blue-text largeFont age text-center" placeholder="100,000" />
            <Form.Text className="text-muted white-text body-font" >
               I'll use this information to be a better PADI lol!
            </Form.Text>
    </Form.Group>


    <Link to="/Padi/setup/financial-information/aspiration">
                      
    <button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font"> Back </button>

    </Link>   
                             
    <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue pal-lime-bg body-font ageSubmission"> Save </button>

    <Link to="/Padi/setup/financial-information/retirement" className="ageContinue">

         <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue body-font"> Continue  </button>     

                                      </Link>    
            
</Form>

</div>


<div className="col-12 col-md-6"> 

      <img src={Birthday} width="100%" /> 
</div>

</div>


<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default age