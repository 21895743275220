import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import contributionSize from '../images/contribution.png';
import numeral from 'numeral'
import '../App.css';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';



class contribution extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           loader:false
 
        }

        this.submitContribution = this.submitContribution.bind(this)
        this.formatNumeral = this.formatNumeral.bind(this)
        this.submissionControl = this.submissionControl.bind(this)

      }
 

      componentDidMount(){
         window.scrollTo(0, 0)
          this.submitContribution()
          this.formatNumeral()
          this.submissionControl()

      }


      async formatNumeral(){
         $('.contribution').keyup(function(){ 
           let formatedValue = numeral($('.contribution').val()).format(0,0)
           console.log(formatedValue)
           $('.contribution').val(formatedValue)
          
           });
       }


       async submissionControl(){

         $('.contribution').change(async function(){ 
 
            $('.contributionContinue').fadeOut('slow', function(){ 
               $('.contributionSubmission').fadeIn('slow')
            })
 
         })
    }

      async submitContribution(){ 

        $('.contributionSubmission').click(async function(e){ 

            e.preventDefault()


            if($('.contribution').val() === ""){
               return alert('Please add a value')
             }
            this.setState({
               loader:true
            })
          
            const contributionData = { 
                 currentContribution : numeral($('.contribution').val()).value()
            }
    
            const pushContribution= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/data/storeCurrentContribution', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(contributionData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
                
                    this.setState({
                       loader:false
                    })

                 $('.contributionSubmission').fadeOut('fast', function(){ 

                    $('.contributionContinue').fadeIn('slow')
                 })
    
    
            })

        }.bind(this))

 
      }

      

      render(){
          return(

            <> 
               <div className="row standard-margin-top"> 

               <div className="col-12 col-md-6 standard-padding-rl"> 

               <Badge className="white-text blue-bg" bg="primary "> About your contributions </Badge>
               
                <h3 className="header-font blue-text"> 
                What is your monthly contribution? 
                </h3>
                <Form className="text-center standard-margin-top">
                   <Form.Group className="mb-3" controlId="">
                        <Form.Control type="text" className="form-design-a blue-text largeFont contribution text-center" placeholder="0" />
                           <Form.Text className="text-muted white-text body-font" >
                              I'll use this information to determine the size of your future RSA balance
                           </Form.Text>
                   </Form.Group>
                                        
    <button onClick="" variant="primary" className="col-10 col-md-2 mx-auto white-text pal-button-blue pal-lime-bg body-font contributionSubmission"> Save  </button>
 
    <Link to="/Padi/setup/financial-information/savings" className="contributionContinue">
 <button onClick="" variant="primary" className="white-text col-10 col-md-2 mx-auto pal-button-blue body-font"> Continue  </button>               
    </Link>  
    </Form>
       
               </div>


               <div className="col-12 col-md-6"> 

               <img src={contributionSize} width="100%" /> 
               </div>

               </div>


<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default contribution