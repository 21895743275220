import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import bus from '../images/bus.jpg';
import car from '../images/car.jpg';
import taxi from '../images/taxi.jpg';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class transportation extends Component{ 


     
    constructor(props){
        super(props)
        this.state= {
           setup:false,
           disabled:true,
           loader:false
        }

        this.submitTransportationChoice =  this.submitTransportationChoice.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
       this.submitTransportationChoice()
      }


      async submitTransportationChoice(){ 

        $('.transportation1').change(async function(){ 
          
            this.setState({ 

                loader:true
            })
           

            let score = parseInt($('.transportation1').val())
  
            const transportationChoiceData = { 
                transportationScore  : score
            }
    
            const pushTransportationChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeTransportationChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(transportationChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
           
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.transportationContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))



        $('.transportation2').change(async function(){ 

            this.setState({ 
                loader:true
            })

            let score = parseInt($('.transportation2').val())
            const transportationChoiceData = { 
                transportationScore  : score
            }
    
            const pushTransportationChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeTransportationChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(transportationChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.transportationContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))


        $('.transportation3').change(async function(){ 

            this.setState({ 
                loader:true
            })
    
            let score = parseInt($('.transportation3').val())

            const transportationChoiceData = { 
                transportationScore  : score
            }
    
            const pushTransportationChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeTransportationChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(transportationChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.transportationContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))

 
      }

      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Transportation </Badge>

 <h3 className="header-font blue-text"> 
 What will be your regular mode of transportation? 
 </h3>                 
</div>


<div className="col-12 col-md-4 answerPan text-center"> 
    <figure id="1">
    <img src={bus} width="100%" /> 


    <input inline 
          className="transportation1"
          type="radio"
          id="2"
           name="transportation"
           value="1"
       

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> Bus </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="5">
    <img src={car} width="100%" /> 


    <input inline 
                             className="transportation2"
                             type="radio"
                             id="2"
                             name="transportation"
                             value="5"
                           

                             />


    <figcaption> 
        <h4 className="header-font blue-text"> Personal Car </h4>
    </figcaption>
    </figure>
</div>


<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="3">
    <img src={taxi} width="100%" /> 

    <input inline 
                             className="transportation3"
                             type="radio"
                             id="3"
                             name="transportation"
                             value="3"
                         

                             />
    <figcaption> 
        <h4 className="header-font blue-text"> taxi </h4>
    </figcaption>
    </figure>
</div>

<Link to="/Padi/setup/lifestyle/choices/healthcare" className="transportationContinue standard-margin-bottom">

         <Button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font" disabled={this.state.disabled}> Continue  </Button>     

                                      </Link>    
</div>

<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default transportation