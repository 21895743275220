import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import publiccare from '../images/public.jpg';
import privatecare from '../images/private.jpg';
import home from '../images/home.jpg';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';

import $ from 'jquery';


class healthcare extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           disabled:true,
           loader:false
 
        }
        this.submitHealthcareChoice = this.submitHealthcareChoice.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitHealthcareChoice() 

      }


      async submitHealthcareChoice(){ 

        $('.healthcare1').change(async function(){ 

          this.setState({ 
            loader:true
        })

            let score = parseInt($('.healthcare1').val())
     
            const healthcareChoiceData = { 
                healthcareScore  : score
            }
    
            const pushHealthcareChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHealthChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(healthcareChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
                 
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.healthcareContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))



        $('.healthcare2').change(async function(){ 
          
          this.setState({ 
       
            loader:true
        })

            let score = parseInt($('.healthcare2').val())
           
            const healthcareChoiceData = { 
                healthcareScore  : score
            }
    
            const pushHealthcareChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHealthChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(healthcareChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.healthcareContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))


        $('.healthcare3').change(async function(){ 

          this.setState({ 
       
            loader:true
        })
            let score = parseInt($('.healthcare3').val())

            const healthcareChoiceData = { 
                healthcareScore  : score
            }
    
            const pushHealthcareChoice= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personality/data/storeHealthChoice', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(healthcareChoiceData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
                 this.setState({ 
                    disabled: false,
                    loader:false
                })
                 $('.healthcareContinue').fadeIn('slow') 
                 $(window).scrollTop(800);
            

            })

        }.bind(this))



 
      }



      render(){
          return(

            <> 
                <div className="row text-center"> 

<div className="col-12 standard-padding-rl text-center"> 
<Badge className="white-text blue-bg" bg="primary "> Healthcare </Badge>

 <h3 className="header-font blue-text"> 
 What is your preferred healthcare solution path?
 </h3>                 
</div>

<div className="col-12 col-md-4 answerPan text-center"> 
    <figure id="1">
    <img src={home} width="100%" /> 
    <input inline 
          className="healthcare1"
          type="radio"
           name="healthcare"
           value="5"
       />
    <figcaption> 
        <h4 className="header-font blue-text"> Home care  </h4>
    </figcaption>
    </figure>
   
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="5">
    <img src={publiccare} width="100%" /> 

    <input inline 
          className="healthcare2"
          type="radio"
           name="healthcare"
           value="1"
       />
    <figcaption> 
        <h4 className="header-font blue-text"> Public care </h4>
    </figcaption>
    </figure>
</div>

<div className="col-12 col-md-4 answerPan text-center"> 

<figure id="3">
    <img src={privatecare} width="100%" /> 


    <input inline 
          className="healthcare3"
          type="radio"
           name="healthcare"
           value="3"
       />


    <figcaption> 
        <h4 className="header-font blue-text"> Private care </h4>
    </figcaption>
    </figure>
</div>

<Link to="/Padi/setup/lifestyle/choices/feeding" className="healthcareContinue standard-margin-bottom">

         <Button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font" disabled={this.state.disabled}> Continue  </Button>     

         </Link>    

</div>

<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default healthcare