import React, {Component} from "react";
import {Link} from "react-router-dom";
import $ from 'jquery';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import padiCircle from '../images/padi-circle.png';
import Plotly from 'plotly.js-dist';
import transparent from '../images/transparent.png';
import Card from "react-bootstrap/Card";
import Nav from "./navigation.js";
import Footer from './footer.js';
import housing from '../images/housing.png';
import feeding from '../images/feeding.png';
import healthcare from '../images/healthCare.png';
import vehicle from '../images/vehicle.png';
import utility from '../images/utility.png';
import leisure  from '../images/leisure.png';
import savings from '../images/potSize.png';
import maleExtravagantBanner from '../images/maleExtravagant.jpg';
import maleModerateBanner from '../images/maleModerate.jpg';
import maleConservativeBanner from '../images/maleConservative.jpg';
import femaleExtravagantBanner from '../images/femaleExtravagant.jpg';
import femaleModerateBanner from '../images/femaleModerate.jpg';
import femaleConservativeBanner from '../images/femaleConservative.jpg';
import numeral from 'numeral';
import Modal1 from 'react-bootstrap/Modal';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';



class report extends Component{


    constructor(props){
        super(props)
        this.state={
           update:false,
           loader:true,
           firstname:"",
           male:false,
           female:false,
           banner:"",
           desiredPensionPot:"",
           currentFutureValue:"",
           yearsToRetirement:"",
           avcValue:"",
           currentPensionPot:"",
           dob:"",
           currentContribution:"",
           expectedBenefit:"",
           lumpSum:"",
           conservativeHealthAllocation: "",
          conservativeHealthPercentage: "",
          moderateHealthAllocation: "",
          moderateHealthPercentage: "",
          extravagantHealthAllocation: "",
          extravagantHealthPercentage: "",
          conservativeFeedingAllocation: "",
          conservativeFeedingPercentage: "",
          moderateFeedingAllocation: "",
          moderateFeedingPercentage:  "",
          extravagantFeedingAllocation: "",
          extravagantFeedingPercentage: "",
          conservativeHousingAllocation: "",
          conservativeHousingPercentage: "",
          moderateHousingAllocation : "",
          moderateHousingPercentage : "",
          extravagantHousingAllocation : "",
          extravagantHousingPercentage : "",
          conservativeTransportationAllocation:"",
          conservativeTransportationPercentage: "",
          moderateTransportationAllocation : "",
          moderateTransportationPercentage: "",
          extravagantTransportationAllocation: "",
          extravagantTransportationPercentage: "",
          conservativeUtilityAllocation: "",
          conservativeUtilityPercentage: "",
          moderateUtilityAllocation: "",
          moderateUtilityPercentage: "",
          extravagantUtilityAllocation: "",
          extravagantUtilityPercentage:"",
          conservativeLeisureAllocation: "",
          conservativeLeisurePercentage: "",
          moderateLeisureAllocation: "",
          moderateLeisurePercentage: "",
          extravagantLeisureAllocation: "",
          extravagantLeisurePercentage:"" ,
          conservativeSavingsPercentage:"",
          moderateSavingsPercentage:"",
          extravagantSavingsPercentage:"",
          personalityIndex:"",
          personalityHeading:"",
          personalityStory:"", 
          personalitySubHeading:"", 
          totalPersonalityScore:""

        }


        this.retrieveUsername = this.retrieveUsername.bind(this)
        this.retrieveFinancialData = this.retrieveFinancialData.bind(this)
        this.backToTop = this.backToTop.bind(this);
        this.FindAVC = this.FindAVC.bind(this);
        this.pmt = this.pmt.bind(this);
        this.FV = this.FV.bind(this);
        this.openUpdate = this.openUpdate.bind(this)
        this.closeUpdate = this.closeUpdate.bind(this)
        this.updateUserResults = this.updateUserResults.bind(this)
        this.formatNumeral = this.formatNumeral.bind(this)
        this.personalityProcessing = this.personalityProcessing.bind(this)
        this.navigationCheck = this.navigationCheck.bind(this)

      }

   async componentDidMount(){
    this.navigationCheck()
    window.scrollTo(0, 0)
         this.retrieveUsername()
       await this.retrieveFinancialData()
        await this.personalityProcessing()

       
        this.backToTop()
  
        let contr= parseInt(20145.43)
        console.log(this.FindAVC(contr))
        console.log(this.pmt(0.1/12,480,2000000,20000000,0))
        console.log(this.FV(0.1/12,480,-contr,2000000,0))
        

      }

      async componentDidUpdate(){
          
      }


      openUpdate(){ 
        this.setState({ 
              update: true 
           }, () => {
            $('.contribution').val(this.state.currentContribution)
            $('.savings').val(this.state.currentPensionPot)
            $('.aspiration').val(this.state.desiredPensionPot)
            $('.retirement').val(this.state.yearsToRetirement)
            console.log(this.state.dob)
            this.updateUserResults()
            this.formatNumeral()
          
          })
      }



      closeUpdate(){ 
             this.setState({ 
               update: false
             })
      }

      

      navigationCheck(){ 

     
        $(document).scroll(function(){ 
          let nav = document.getElementById('nav1')
            if($(window).scrollTop() > 1){
    
              
                 
                nav.style.backgroundColor = 'rgba(255,255,255,0.85)'
    
                console.log("one")
            }else{
                 
                nav.style.backgroundColor = 'transparent'
                console.log("two")
            }
    
        })   
    
      }


      async updateUserResults(){ 
                
        $('.resultUpdate').click(async function(){ 
          //get all input field values
          let currentContribution = numeral($('.contribution').val()).value()
          let desiredPensionPot = numeral($('.aspiration').val()).value()
          let currentPensionPot = numeral($('.savings').val()).value()
          let yearsToRetirement = $('.retirement').val()
    

          let resultUpdateObject = { 
              currentContribution, 
              desiredPensionPot,
              currentPensionPot,
              yearsToRetirement,
            }

            console.log(resultUpdateObject)
            alert("start")

            const pushUpdateData = await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/Data/updateResult', { 
              method:"POST",
              headers:{
                "content-type":"application/JSON"
              },
              body: JSON.stringify(resultUpdateObject),
              mode:"cors",
              credentials:'include' 
            })
        
          .then(response => response.json())
          .then(response => {  

            console.log("updated")
            console.log(response)
            let positiveAVCValue = response.avcValue

               this.setState({

                desiredPensionPot: numeral(response.desiredPensionPot).format(0,0),
                currentFutureValue : numeral(response.currentFutureValue).format(0,0),
                yearsToRetirement : response.yearsToRetirement,
                avcValue : numeral(positiveAVCValue).format(0,0),
                currentContribution: numeral(response.currentContribution).format(0,0),
                currentPensionPot : numeral(response.currentPensionPot).format(0,0),
                update: false,

               }, ()=>{ 
                window.location.replace("https://palCalc.palpensions.com/Padi/setup/advisory/results")
               })


          })




        }.bind(this))

      }

      backToTop(){
        $("#reportScroll").on("click", function() {
            console.log("clicked")
            $(window).scrollTop(800);
        });
      }


      async formatNumeral(){
        $('.contribution').keyup(function(){ 
          let formatedValue = numeral($('.contribution').val()).format(0,0)
          console.log(formatedValue)
          $('.contribution').val(formatedValue)
         
          });


          $('.savings').keyup(function(){ 
            let formatedValue = numeral($('.savings').val()).format(0,0)
            console.log(formatedValue)
            $('.savings').val(formatedValue)
           
            });
  
            $('.aspiration').keyup(function(){ 
              let formatedValue = numeral($('.aspiration').val()).format(0,0)
              console.log(formatedValue)
              $('.aspiration').val(formatedValue)
             
              });
            

      }


      async retrieveFinancialData(){ 
      const pullFinancialData = await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/data/getfinancialfeedback', { 
      method:"POST",
      headers:{
        "content-type":"application/JSON"
      },
      mode:"cors",
      credentials:'include' 
    })

  .then(response => response.json())
  .then(response => {  
    
    console.log(response)
    if(response.personalityIndex.personalityIndex){
    console.log(response.personalityIndex.personalityIndex)
   


    let positiveAVCValue = response.financialBasics.avcValue

    //fill update form with user details

 



    this.setState({  
          personalityIndex: response.personalityIndex.personalityIndex,
          totalPersonalityScore: response.personalityIndex.totalPersonalityScore,
          desiredPensionPot: numeral(response.financialBasics.desiredPensionPot).format(0,0),
          currentFutureValue : numeral(response.financialBasics.currentFutureValue).format(0,0),
          yearsToRetirement : response.financialBasics.yearsToRetirement,
          avcValue : numeral(positiveAVCValue).format(0,0),
          currentContribution: numeral(response.financialBasics.currentContribution).format(0,0),
          currentPensionPot : numeral(response.financialBasics.currentPensionPot).format(0,0),
          expectedBenefit: numeral(response.financialBasics.expectedBenefit).format(0,0),
          lumpSum: numeral(response.financialBasics.lumpSum).format(0,0),
          conservativeHealthAllocation: numeral(response.conservativeHealth.allocation).format(0,0),
          conservativeHealthPercentage: numeral(response.conservativeHealth.percentage).format(0,0),
          moderateHealthAllocation: numeral(response.moderateHealth.allocation).format(0,0),
          moderateHealthPercentage: numeral(response.moderateHealth.percentage).format(0,0),
          extravagantHealthAllocation: numeral(response.extravagantHealth.allocation).format(0,0),
          extravagantHealthPercentage: numeral(response.extravagantHealth.percentage).format(0,0),
          conservativeFeedingAllocation: numeral(response.conservativeFeeding.allocation).format(0,0),
          conservativeFeedingPercentage: numeral(response.conservativeFeeding.percentage).format(0,0),
          moderateFeedingAllocation: numeral(response.moderateFeeding.allocation).format(0,0),
          moderateFeedingPercentage:  numeral(response.moderateFeeding.percentage).format(0,0),
          extravagantFeedingAllocation:   numeral(response.extravagantFeeding.allocation).format(0,0),
          extravagantFeedingPercentage:  numeral(response.extravagantFeeding.percentage).format(0,0),
          conservativeHousingAllocation:  numeral(response.conservativeHousing.allocation).format(0,0),
          conservativeHousingPercentage: numeral(response.conservativeHousing.percentage).format(0,0),
          moderateHousingAllocation : numeral(response.moderateHousing.allocation).format(0,0),
          moderateHousingPercentage : numeral(response.moderateHousing.percentage).format(0,0),
          extravagantHousingAllocation : numeral(response.extravagantHousing.allocation).format(0,0),
          extravagantHousingPercentage : numeral(response.extravagantHousing.percentage).format(0,0),
          conservativeTransportationAllocation: numeral(response.conservativeTransportation.allocation).format(0,0),
          conservativeTransportationPercentage: numeral(response.conservativeTransportation.percentage).format(0,0),
          moderateTransportationAllocation : numeral(response.moderateTransportation.allocation).format(0,0),
          moderateTransportationPercentage: numeral(response.moderateTransportation.percentage).format(0,0),
          extravagantTransportationAllocation: numeral(response.extravagantTransportation.allocation).format(0,0),
          extravagantTransportationPercentage: numeral(response.extravagantTransportation.percentage).format(0,0),
          conservativeUtilityAllocation: numeral(response.conservativeUtility.allocation).format(0,0),
          conservativeUtilityPercentage: numeral(response.conservativeUtility.percentage).format(0,0),
          moderateUtilityAllocation: numeral(response.moderateUtility.allocation).format(0,0),
          moderateUtilityPercentage: numeral(response.moderateUtility.percentage).format(0,0),
          extravagantUtilityAllocation: numeral(response.extravagantUtility.allocation).format(0,0),
          extravagantUtilityPercentage: numeral(response.extravagantUtility.percentage).format(0,0),
          conservativeLeisureAllocation: numeral(response.conservativeLeisure.allocation).format(0,0),
          conservativeLeisurePercentage: numeral(response.conservativeLeisure.percentage).format(0,0),
          moderateLeisureAllocation: numeral(response.moderateLeisure.allocation).format(0,0),
          moderateLeisurePercentage: numeral(response.moderateLeisure.percentage).format(0,0),
          extravagantLeisureAllocation: numeral(response.extravagantLeisure.allocation).format(0,0),
          extravagantLeisurePercentage: numeral(response.extravagantLeisure.percentage).format(0,0),
          conservativeSavingsPercentage: numeral(response.conservativeSavings.percentage).format(0,0),
          moderateSavingsPercentage: numeral(response.moderateSavings.percentage).format(0,0),
          extravagantSavingsPercentage: numeral(response.extravagantSavings.percentage).format(0,0),
          loader:false
    }, () =>{ 
      console.log(response.personalityIndex)
      var data = [
        {
          domain: { x: [0, 1], y: [0, 1] },
          value: this.state.totalPersonalityScore,
          title: { text: "Financial Personality Guage", font:{color:"#23b7af", family:"Bauhaus", size:20}},
          type: "indicator",
          mode: "gauge+number",
          delta: { reference: 400 },
          number:{font:{color:"#23b7af", family:"Bauhaus"}},
          gauge: { axis: { range: [null, 100] }, bordercolor: '#005aa1', bar:{color:"#23b7af"}, bgColor:"#005aa1" }
        }
      ];

      var config = {responsive: true}
      
      var layout = { width:"", height:""};
      Plotly.newPlot('scoreLine', data, layout, config);
    })

  }else{

    window.location.href = "https://palcalc.palpensions.com/Padi/setup/advisory/results"
  }
       
              })

     


      }



      async retrieveUsername(){


        const pullFirstname= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/getfirstname', { 
      method:"POST",
      headers:{
        "content-type":"application/JSON"
      },
      mode:"cors",
      credentials:'include' 
    })

  .then(response => response.json())
  .then(response => {  

    console.log(response)
    this.setState({ 

        firstname: response.firstname,
        male: response.male,
        female:response.female
    })

  })
            
    }



    async pmt(rate_per_period, number_of_payments, present_value, future_value, type){
        if(rate_per_period != 0.0){
            // Interest rate exists
            var q = Math.pow(1 + rate_per_period, number_of_payments);
            let ans= (rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));
             return parseInt(ans)
        } else if(number_of_payments != 0.0){
            // No interest rate, but number of payments exists
            return -(future_value + present_value) / number_of_payments;
        }
    
        return 0;
    }
    

  async FindAVC(currentPensionPot){
           let payment =await this.pmt(0.1/12,480,2000000,20000000,0)
           console.log(typeof payment)
           console.log(typeof currentPensionPot)

            let p = await parseInt(payment)
            console.log(typeof p)
            
             
           let Avc = currentPensionPot - p

           console.log(Avc)

             
   
  }



  async personalityProcessing(){
    //personalitydescision 
    let conservativeRecommendation = document.getElementById('conservativeRecommendation')
    let moderateRecommendation = document.getElementById('moderateRecommendation')
    let extravagantRecommendation = document.getElementById('extravagentRecommendation')
    
    if(this.state.personalityIndex === 1){
    $('#conservativeRecommendation').fadeIn()
       this.setState({
          personalityHeading: "The Conservative Type" ,
          personalitySubHeading: "Retire to a life ",
          personalityStory:"Regardless of how wealthy you are or may become, your natural habits ensure you always have more than you require. You are  satisfied with the company of your family or being engaged by a hobby of your choosing. Friends are important and you keep them around but are not directed by their actions and they have the least impact on your finances. You are extremely particular about things like food, health and vacations and most of the time prefer to be fully in control of these parts of your life."
       })
    
    if(this.state.male){
         this.setState({
           banner: maleConservativeBanner
         })
    }

    if(this.state.female){
      this.setState({
        banner: femaleConservativeBanner
      })
 }


    }else if(this.state.personalityIndex === 2){
      $('#moderateRecommendation').fadeIn()

    this.setState({
      personalityHeading: "The Moderate Type" ,
      personalitySubHeading: "Retire to a life of quality not quantity",
      personalityStory:"You understand that life is meant to be lived but you are conscious to not let yourself go overboard. Giving yourself a treat is important to you and you do not opt for anything but the best when it comes to matters relating to your wellbeing. Slightly extroverted friends and family are a big deal and creating time to schedule activities is not considered an expense but an investment in a quality life."
   
     })


      if(this.state.male){
        this.setState({
          banner: maleModerateBanner
        })
   }

   if(this.state.female){
     this.setState({
       banner: femaleModerateBanner
     })
}


      }else if(this.state.personalityIndex === 3){
        $('#extravagantRecommendation').fadeIn()
        
        this.setState({
          personalityHeading: "The Extravagant Type" ,
          personalitySubHeading: "Retire to a life of self exploration",
          personalityStory:"You are financially savvy but intentional about choosing to give your impulses the freedom to express. The largest impact on your finances comes from this need to explore unconditionally.  You maintain a network who appreciate this approach towards life. Living for today is scary to some but not you. You have a strong belief in your ability to maintain a life that is both premium and financially secure.  You love life and see no reason to not live… to the fullest."
       
         })

        if(this.state.male){
          this.setState({
            banner: maleExtravagantBanner
          })
     }

     if(this.state.female){
       this.setState({
         banner: femaleExtravagantBanner
       })
  }
        }
  }


  async FV(rate, nper, pmt, pv, type) {
    var pow = Math.pow(1 + rate, nper),
       fv;
    if (rate) {
     fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
    } else {
     fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(0);
  }





      render(){


      

        let breakdownIntro
        let breakdown 

        console.log(this.state.avcValue)

        if(parseInt(this.state.avcValue) > 0) { 

          breakdownIntro =  <div className="col-12 col-md-8 standard-margin-bottom transparent-background">
          <h1 className="header-font white-text monsterFont"> 
              Whoop!!! {this.state.firstname}!
          </h1>
           <h5 className="white-text body-font spaced-text mobileh5">
              from my understanding of your retirement aspirations
           </h5>
           <hr className="white-bg full-width divider-style" />
           <h5 className="white-text body-font spaced-text mobileh5">
           you are going to achieve the  <span className="lime-text body-font white-text limeBox">  <span className="header-font "> retirement of your dreams </span> </span>
           </h5>

           </div>

           breakdown =        <div className="col-12 col-md-7 transparent-background"> 
                                  
           <h6 className="body-font white-text"> 
            Your minimum balance at retirement would be
           </h6>

           <h1 className="header-font white-text LargeFont spaced-text"> 
             N{this.state.currentFutureValue}
               
           </h1>

                        
           <h6 className="body-font white-text"> 
            and, based on my understanding of your goals, you are ready to live the life you aspire towards post retirement and
            have extra for further investments. At retirement you can expect a likely lump sum payment of 
           </h6>

           <h1 className="header-font white-text LargeFont spaced-text"> 
             N{this.state.lumpSum}
               
           </h1>

           <h6 className="body-font white-text"> 
            before your monthly pension payments commence.
           </h6>

            <div className="row full-width standard-margin-top standard-margin-bottom mOff">
           <div className="col-12 col-md-6 text-center ">

           </div>

           </div>
     </div>

        }


        if(parseInt(this.state.avcValue) < 0 ){

            let positiveAVCValue = numeral(Math.abs(numeral(this.state.avcValue).value())).format(0,0)
            console.log(positiveAVCValue)

           breakdownIntro =   <div className="col-12 col-md-8 standard-margin-bottom transparent-background">
           <h1 className="header-font white-text monsterFont"> 
               OOPs {this.state.firstname}!
           </h1>
            <h5 className="white-text body-font spaced-text mobileh5">
               from my understanding of your retirement aspirations
            </h5>
            <hr className="white-bg full-width divider-style" />
            <h5 className="white-text body-font spaced-text mobileh5 ">
            you will need to contribute <span className="lime-text body-font white-text limeBox">  <span className="header-font "> N{positiveAVCValue} extra monthly </span> </span>
            </h5>

            </div>

            breakdown =             <div className="col-12 col-md-7 transparent-background"> 
                                  
            <h6 className="body-font white-text"> 
             Your desired balance at retirement is
            </h6>

            <h1 className="header-font white-text LargeFont spaced-text"> 
              N{this.state.desiredPensionPot}
                
            </h1>

            <h6 className="body-font white-text"> 
             and your future total savings will be 
            </h6>

            <h1 className="header-font white-text LargeFont spaced-text"> 
              N{this.state.currentFutureValue}
                
            </h1>
                         
            <h6 className="body-font white-text"> 
             based on my understanding of your goals, you will need to top up by
            </h6>

            <h1 className="header-font white-text LargeFont spaced-text"> 
              N{positiveAVCValue}/month 
                
            </h1>
            <h6 className="body-font white-text"> 
           from next month, to live the retirement of your dreams in {this.state.yearsToRetirement}years.
           We recommend that you subscribe to the Additional Voluntary Contribution Product (AVC) in order to 
           top-up your monthly savings.
            </h6>
             <div className="row full-width standard-margin-top standard-margin-bottom mOff">
             <div className="col-12 col-md-6 text-center ">
<a href="https://www.palpensions.com/services/avc/" target="_blank">
<Button  variant="primary" className="col-12 pal-button-blue body-font smallFont standard-margin-bottom"> Subscribe to AVC </Button>
</a>
</div>
            <div className="col-12 col-md-6 text-center ">

            </div>

            
            </div>
      </div>


        }

       
if(this.state.loader){
  return(

    <>

    <Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Analysing your results {this.firstname}  </h3>
        </Modal.Body>
      </Modal>


    </>
  )

} else {


        

        return(
            <>
                <Nav />

                <div className="row reports-landing-bg full-width mOff" style={{ backgroundImage: `url(${this.state.banner})`, backgroundSize:"cover", backgroundPosition:"center center", backgroundAttachment:"fixed" }}> 
                <div className="col-12 d-block d-md-none pOff">
                <img className="img-fluid" src={this.state.banner} width="100%" />
                </div>
              

                <div className="row container-fluid gradient-blue-bg transparent-background report-container mOff"> 
                <div className="col-12 col-md-2 transparent-background text-center">
                <img className="standard-margin-top img-fluid padiCircle" src={padiCircle} />
                </div>
                  {breakdownIntro}

                     <div className="col-12 col-md-2 transparent-background">
                           
            <Button onClick={this.closeModal} variant="primary" id="reportScroll" className="col-12 pal-button-blue pal-lime-bg body-font smallFont"> Find out more </Button>
                 
                 
                     </div>

                     <div className="gradient-white-bg container-fluid full-width">
                         <div className="row transparent-background standard-margin-top">
                          <div className="col-12 col-md-4 transparent-background"> 
                                  
                           
                          </div>

                             {breakdown}
                        

                         </div>

                         <div className="row standard-margin-top standard-padding-rl ">


                         <div className="col-12 offset-md-1 col-md-2 transparent-background">
                <img className="standard-margin-top img-fluid padiCircle" src={padiCircle} />
                </div>
                <div className="col-12 col-md-8 standard-margin-bottom transparent-background">
                   
                     <h2 className="white-text header-font spaced-text ">
                     {this.state.firstname}, looking at Lifestyle choices from your personality test
                     </h2>
                     <hr className="white-bg full-width divider-style" />
                     <h6 className="white-text body-font spaced-text">
                         here are some insights into your financial personality and recommendations on how you can plan your monthly benefit below
                         </h6>

                     </div>

                 <div className="standard-margin-bottom">
                     <div className="col-12 header-font white-text text-center standard-margin-bottom">
                  <h3 className="header-font">  Read through our understanding of your financial personality </h3>   
                     </div>
                     < div className="col-12 col-md-6 mx-auto">
    
                     <Card className="pOff"> 
                         <Card.Img variant="top" src={this.state.banner} />
                             <Card.Body>
                               <Card.Title>
                             <h3 className="header-font blue-text"> {this.state.firstname}, you are </h3>   
                             <h3 className="header-font blue-text"> <span className="lime-text body-font white-text limeBox"> {this.state.personalityHeading}  </span></h3>   
                             
                             <h5 className="header-font blue-text"> {this.state.personalitySubHeading} </h5>
                                 </Card.Title>
                              <Card.Text>
                                 <p className="body-font standard-padding-tb">
                                 {this.state.personalityStory}
                                   </p> 
                              </Card.Text>
                            
                             </Card.Body>
                     </Card>
                     </div>   

                     <div className="col-12 col-md-6 white-bg mx-auto"> 

                     <div className="col-12" id="scoreLine">

                     </div>
                     
              

<a href="/Padi/setup/lifestyle/choices/housing" className="standard-margin-bottom">
<Button onClick={this.openFinal} variant="primary" className="col-12 pal-button-blue body-font smallFont"> Retake Personality Test </Button>
</a>                

                           
                     </div>   
                 </div>    

                <div className="fullwidth">
                <div className="row">

                <div className="col-12 header-font white-text text-center standard-margin-bottom">
                  <h3 className="header-font">  Here's a breakdown of how you can maximize your finances in retirement... </h3>   
                     </div>
                    <div className=" col-12 col-md-3 mx-auto gradient-white-bg lifestyle-borderdesign standard-margin-bottom standard-padding-rlb">
                    <div className="recommendationBox standard-margin-bottom">
                    <Badge className="blue-text header-font white-bg" id="conservativeRecommendation" bg="primary "> Recommended based on your personality </Badge>
                    </div>
                       <table className="white-text col-12 standard-margin-bottom"> 
                           <thead className="header-font" >
                               <tr > 
                                   <td className="col-md-4"> Lifestyle </td>
                                   <td className="col-md-2"> % </td>
                               </tr>
                           </thead>
                           <tbody className="body-font" >

                           <tr> 
                                   <td > <img src={healthcare} width="20%" />   Health </td>
                                   <td> {this.state.conservativeHealthPercentage}% </td>
                               </tr>


                               <tr> 
                                     <td > <img src={feeding} width="20%" /> Food  </td>
                                   <td> {this.state.conservativeFeedingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={housing} width="20%" />  Housing </td>
                                   <td> {this.state.conservativeHousingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={vehicle} width="20%" />   Mobility </td>
                                   <td> {this.state.conservativeTransportationPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={utility} width="20%" />   Utility </td>
                                   <td> {this.state.conservativeUtilityPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={leisure} width="20%" />   Leisure </td>
                                   <td> {this.state.conservativeLeisurePercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={savings} width="20%" />   Savings </td>
                                   <td> {this.state.conservativeSavingsPercentage}% </td>
                                </tr>
                           </tbody>
                       </table>

                       </div>


                       <div className=" col-12 col-md-3 mx-auto gradient-white-bg lifestyle-borderdesign standard-margin-bottom standard-padding-rlb">
                       <div className="recommendationBox standard-margin-bottom">
                       <Badge className="blue-text header-font white-bg" id="moderateRecommendation" bg="primary "> Recommended based on your personality </Badge>
                       </div>
<table className="white-text col-12 standard-margin-bottom"> 
    <thead className="header-font" >
        <tr > 
            <td className="col-md-4"> Lifestyle </td>
            <td className="col-md-2"> % </td>
        </tr>
    </thead>
    <tbody className="body-font" >

    <tr> 
                                   <td > <img src={healthcare} width="20%" />   Health </td>
                                   <td> {this.state.moderateHealthPercentage}% </td>
                               </tr>


                               <tr> 
                                     <td > <img src={feeding} width="20%" /> Food  </td>
                                   <td> {this.state.moderateFeedingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={housing} width="20%" />  Housing </td>
                                   <td> {this.state.moderateHousingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={vehicle} width="20%" />   Mobility </td>
                                   <td> {this.state.moderateTransportationPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={utility} width="20%" />   Utility </td> 
                                   <td> {this.state.moderateUtilityPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={leisure} width="20%" />   Leisure </td>
                                   <td> {this.state.moderateLeisurePercentage}% </td>
                                </tr>
                                <tr> 
                                     <td > <img src={savings} width="20%" />   Savings </td>
                                   <td> {this.state.moderateSavingsPercentage}% </td>
                                </tr>
    </tbody>
</table>

</div>



<div className=" col-12 col-md-3 mx-auto gradient-white-bg lifestyle-borderdesign standard-margin-bottom standard-padding-rlb">
  <div className="recommendationBox standard-margin-bottom">
<Badge className="blue-text header-font white-bg" id="extravagantRecommendation" bg="primary "> Recommended based on your personality </Badge>
</div>
<table className="white-text col-12 standard-margin-bottom"> 
    <thead className="header-font" >
        <tr > 
            <td className="col-md-4"> Lifestyle </td>
            <td className="col-md-2"> % </td>
        </tr>
    </thead>
    <tbody className="body-font" >

   
    <tr> 
                                   <td > <img src={healthcare} width="20%" />   Health </td>
                                   <td> {this.state.extravagantHealthPercentage}% </td>
                               </tr>


                               <tr> 
                                     <td > <img src={feeding} width="20%" /> Food  </td>
                                   <td> {this.state.extravagantFeedingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={housing} width="20%" />  Housing </td>
                                   <td> {this.state.extravagantHousingPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={vehicle} width="20%" />   Mobility </td>
                                   <td> {this.state.extravagantTransportationPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={utility} width="20%" />   Utility </td>
                                   <td> {this.state.extravagantUtilityPercentage}% </td>
                                </tr>

                                <tr> 
                                     <td > <img src={leisure} width="20%" />   Leisure </td>
                                   <td> {this.state.extravagantLeisurePercentage}% </td>
                                </tr>
                                <tr> 
                                     <td > <img src={savings} width="20%" />   Savings </td>
                                   <td> {this.state.extravagantSavingsPercentage}% </td>
                                </tr>
    </tbody>
</table>

</div>
                       

                     </div>
                      <div className="row full-width standard-margin-bottom rmMarginLeft">

         <div className="col-12 col-md-6 transparent-background mx-auto">
                           
                           <Button onClick={this.openUpdate} variant="primary" className="col-12 pal-button-blue body-font smallFont"> Adjust your finances {this.state.firstname} </Button>
                                
                                
                                    </div>
                     </div>
                         </div>
                         </div>

                     </div>
                  
                </div>
                <Footer />
             </div> 



      <Modal1 className="modal1" show={this.state.update}>
        <Modal1.Header className="white-bg">
          <Modal1.Title className="modal1 blue-text header-font">
            <img src={padiCircle} width="20%" />
             <h1> {this.state.firstname}, adjust and save your information </h1> </Modal1.Title>
          <Button variant="secondary" className="text-end pal-button-blue" onClick={this.closeUpdate}>Close</Button>
        </Modal1.Header>
        <Modal1.Body className="modal1 white-bg blue-text standard-padding-rl">
            <div className="row text-center" id="details-row" >

            <div className="col-12 col-md-6 text-center">

                <div id="fullName">

                <h3 className="header-font blue-text"> 
                  Current Monthly Contribution
                           </h3>
              
                    
             <Form className="text-center">
             <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="text" className="form-design-a text-center blue-text largeFont contribution" placeholder="100000" />
                  <Form.Text className="white-text body-font" >

                  </Form.Text>
          
                 
               </Form.Group>
         </Form>
        
                </div>
         
                          
             
               

            </div>


            <div className="col-12 col-md-6 text-center">

<div id="fullName">

<h3 className="header-font blue-text"> 
           Current Pension Pot size
           </h3>

    
<Form className="text-center">
<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Control type="text" className="form-design-a text-center blue-text largeFont savings" placeholder="100000" />
<Form.Text className="white-text body-font" >
    
  </Form.Text>

 
</Form.Group>
</Form>


</div>

          



</div>


<div className="col-12 col-md-6 text-center">

<div id="fullName">

<h3 className="header-font blue-text"> 
           Desired Pension Pot Size
           </h3>

    
<Form className="text-center">
<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Control type="text" className="form-design-a text-center blue-text largeFont aspiration" placeholder="100000" />
  <Form.Text className="white-text body-font" >
      
  </Form.Text>

 
</Form.Group>
</Form>

</div>

          



</div>

<div className="col-12 col-md-6 text-center">

<div id="fullName">

<h3 className="header-font blue-text"> 
           No of years to Retirement
           </h3>

    
<Form className="text-center">
<Form.Group className="mb-3" controlId="formBasicEmail">
<Form.Control type="number" className="form-design-a text-center blue-text largeFont retirement" placeholder="40" />
  <Form.Text className="white-text body-font" >
        
  </Form.Text>

 
</Form.Group>
</Form>

</div>

</div>


<div className="col-12 text-center standard-margin-top-half">

<div id="fullName ">
        
           <Button variant="primary" className="col-12 col-md-6 pal-button-blue pal-lime-bg body-font resultUpdate" id="submitFullName"> Update my results </Button>

</div>



          



</div>

   </div>
         
            
            </Modal1.Body>
      </Modal1>
            </>




        )

}
      }



}

export default report