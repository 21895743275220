import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import lcLogo from '../images/lc-logo-w.png';
import $ from 'jquery/dist/jquery.js';


class navigation extends Component { 


    constructor(props){
         super(props)
         this.state={
  
         }

         this.navigationCheck = this.navigationCheck.bind(this)
         this.tokenVerification = this.tokenVerification.bind(this)
       }
  
  
  componentDidMount(){ 
    this.navigationCheck()
    this.tokenVerification()
    
  }



async tokenVerification(){ 

  const tokenCheck = await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/verifyToken',{ 

    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
   mode:"cors",
   credentials:"include"
  
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
      let current = window.location.pathname
        if(current != "/"){
          window.location.href = "/"
        }
      }

})

}
  
  navigationCheck(){ 

     
    $(document).scroll(function(){ 
      let nav = document.getElementById('nav1')
        if($(window).scrollTop() > 1){

          
             
            nav.style.backgroundColor = 'rgba(17,101,173,0.85)'

            console.log("one")
        }else{
             
            nav.style.backgroundColor = 'transparent'
            console.log("two")
        }

    })   

  }


  
  render(){
  
  
    return(
  
        <Navbar id="nav1" fixed="top">
        <Container>
          <Navbar.Brand href="/">
          <img
           src={lcLogo}
           width="300"
           height=""
           className="d-inline-block img-fluid align-top"
           alt="retirement lifestyle"
           />
              </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
            <a href="https://www.palpensions.com/need-a-pal/" data_target="blank"  className="body-font blue-text">
            <Button onClick={this.closeModal} variant="primary" className="col-12 pal-button-blue body-font smallFont"> Transfer RSA </Button>
             </a>
                 
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    );

  }
}


export default navigation