import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Nav from './navigation.js'
import Button from 'react-bootstrap/Button';
import padi from '../images/padi.png';
import padiCircle from '../images/padi-circle.png';
import Carousel from 'react-bootstrap/Carousel';
import Carousel1 from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import focus from '../images/focus.png';
import carBubble from '../images/car-bubble.png';
import financialAdvise from '../images/advise.png';
import Footer from './footer';
import activate from '../images/activate.png';
import airplaneBubble from '../images/airplanebubble.png';
import houseBubble from '../images/housebubble.png';
import lifestyleChoices from '../images/lifestylepack.png'; 
import Modal1 from 'react-bootstrap/Modal';
import Modal from 'react-bootstrap/Modal';


class home extends Component { 


    constructor(props){
         super(props)
         this.state={

            setup:false,
            firstname:"",
            lastname:"", 
            gender:"",
            loader:false
  
         }

         this.setupPadi = this.setupPadi.bind(this);
         this.closeSetupPadi = this.closeSetupPadi.bind(this);
         this.backToTop = this.backToTop.bind(this);
         this.submitFullName = this.submitFullName.bind(this)
         this.backToMail = this.backToMail.bind(this);
         this.backToFullname = this.backToFullname.bind(this);
         this.validateEmail = this.validateEmail.bind(this);

         

       }
  
  
  componentDidMount(){ 
    window.scrollTo(0, 0)
     this.navigationCheck();
     this.backToTop();
     this.backToFullname()

  }


  componentDidUpdate(){
    this.submitFullName()
    this.backToFullname()
  // this.submitGender()
  
    //this.setGenderValue()
  
    this.backToMail()
  
  }
  
  
 validateEmail(inputText)
  {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(inputText.match(mailformat))
  {
   alert("Valid email address!");

   return true
  
  }
  else
  {
  alert("You have entered an invalid email address!");
  return false
  }
  }


  navigationCheck(){ 

     
    $(document).scroll(function(){ 

        if($(window).scrollTop() > 1){

            let nav = document.getElementById('nav1')
             
            nav.style.backgroundColor = 'rgba(17,101,173,0.85)'

            console.log("one")
        }else{
            let nav = document.getElementById('nav1')
             
            nav.style.backgroundColor = 'transparent'
            console.log("two")
        }

    })   

  }


  async submitFullName(){

    $('#submitFullName').click(function(){


      if($('.fullname').val() === ""){
        return alert('Please add a value')
      }

          this.setState({
            loader:true
          })
      //update state with values
  this.setState({
          firstname: $('.fullname').val(),
          lastname: $('.fullname').val()
      },async () => { 

//submit data values to server 


const fullname = {
  firstname: this.state.firstname,
  lastname: this.state.lastname
}

let firstname = this.state.firstname

let text2 = "Great to meet you " + firstname + ". Ok, next I'm going to need an address to send you details on your progress towards the retirement of your dreams!"
      


console.log(fullname)

  const pushFullName= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/storefullname', { 

      method:"POST",
      headers:{
        "content-type":"application/JSON"
      },
      body:JSON.stringify(fullname),
      mode:"cors",
      credentials:'include' 
    })

  .then(response => response.json())
  .then(response => {
      

      console.log(response)

      this.setState({
        loader:false
      })

  $('#fullName').fadeOut('slow', function(){ 
    
          $('#emailAddy').fadeIn('slow', function(){ 

              let padiTalk = document.getElementById('padi-talk')
              padiTalk.innerHTML =  text2      })


              $('#emailSubmission').click( async function(){ 


                if($('#email').val() === ""){
                  return alert('Please add a value')
                }


            if(!this.validateEmail($('#email').val())){
                  return false
            }    


                this.setState({
                  loader:true
                })
            
                   const emailData = { 
                       email: $('#email').val()
                   } 
            
                   console.log(this.state.firstname)
                   let firstname = this.state.firstname
            
                   let text3 = "Got it " + firstname + ". I just sent you my first letter. Oops!! One last thing before we proceed. What is your gender?"
                  
                   
                    //forward email to api
            

                  const pushEmail= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/storeemailaddress', { 

                  method:"POST",
                  headers:{
                    "content-type":"application/JSON"
                  },
                  body:JSON.stringify(emailData),
                  mode:"cors",
                  credentials:'include' 
                })
            
              .then(response => response.json())
              .then(response => { 
                this.setState({
                  loader:false
                })
             
                $('#emailAddy').fadeOut('slow', function(){ 
                
                    $('#gender').fadeIn('slow', function(){ 

                        let padiTalk = document.getElementById('padi-talk')
                        padiTalk.innerHTML =  text3   


                     

                          
                          $('#Submission').click(async function(){ 

                            if($('.gender').val() === ""){
                              return alert('Please add a value')
                            }
                              
                            this.setState({
                              loader:true
                            })
                              const genderData = {
                                  gender: $('.gender').val()
                              }

                              console.log(genderData)
                      
                      const pushGender= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/personal/data/storegender', { 

                      method:"POST",
                      headers:{
                      "content-type":"application/JSON"
                      },
                      body:JSON.stringify(genderData),
                      mode:"cors",
                      credentials:'include' 
                      })
                      
                      .then(response => response.json())
                      .then(response => { 
                        this.setState({
                          loader:false
                        })
                      
                      $('#details-row').fadeOut('slow', function(){ 
                      
                      $('#info-row').fadeIn('slow', function(){ 
                      
                      
                      
                      
                      
                      })
                      
                      })
                      
                      })
                      
                      
                      
                       }.bind(this))
                      
                    }.bind(this))
            
                   
                }.bind(this))
              
            })
            
                
               }.bind(this))


     

         
      }.bind(this))

        

  
      })



      })

    

    }.bind(this))
}



backToFullname(){

    $('#backToFullname').click(function(){ 
   
        let firstname = this.state.firstname
    
        let text1 = " Hi, I’m PADI! What's your name? "
              
        
            $('#emailAddy').fadeOut('slow', function(){ 
            
                $('#fullName').fadeIn('slow', function(){ 
        
                    let padiTalk = document.getElementById('padi-talk')
                    padiTalk.innerHTML =  text1 
                  
                })
    
     })
    
        }.bind(this))
       
   }


backToMail(){

 $('#backToMail').click(function(){ 

    let firstname = this.state.firstname

    let text2 = "Great to meet you " + firstname + ". Ok, next I'm going to need an address to send you details on your progress towards the retirement of your dreams!"
          
    
        $('#gender').fadeOut('slow', function(){ 
        
            $('#emailAddy').fadeIn('slow', function(){ 
    
                let padiTalk = document.getElementById('padi-talk')
                padiTalk.innerHTML =  text2  
              
            })

 })

    }.bind(this))
    
}



  backToTop(){
    $("#test, #activate").on("click", function() {
        console.log("clicked")
        $(window).scrollTop(0);
    });
  }


  setupPadi(){
     this.setState({ 
               setup:true
     })
  }

  closeSetupPadi(){
    this.setState({ 
        setup:false
})
  }
  
  
  render(){
  
  
    return(
 

   <> 
         <Nav />

         <div className="container-fluid pOff">
      <div className="row landingCover mOff">

          <div className="container row nav-clearance"> 
            <div className="col-md-6 standard-margin-top d-none d-sm-block">
                <img src={padi} className="padi-position" width="40%" />

                <div className="col-md-3 absoluted text-pane white-bg black-text body-font textpane-borderRadius text-pane-home standard-padding-rl">
                                 Hey, I’m PADI!
                                       Sticking to your personal finance
                                        plans for the future
                                        can be difficult, but I'm 
                                        here to help!
                     </div>
                 </div>
                 <div className="col-12 col-md-6">
                     <div className="col-12 standard-margin-bottom">
                     <h3 className="white-text body-font spaced-text">
                         Who better to tell about the future
                     </h3>
                     <hr className="white-bg full-width divider-style" />
                     <h3 className="white-text body-font spaced-text">
                        of your dreams than your <span className="lime-text header-font limeBox"> PAL. </span>
                     </h3>
                </div>

                <div className="col-12 standard-margin-bottom">
                    <p className="body-font white-text">
                    Take your PADI on a journey into your life. By providing
                    information on your current contributions and retirement 
                    aspirations, your PADI will provide financial advisory to help you 
                    achieve the life you aspire to post retirement.
                    </p>
                </div>

                <div className="col-8 col-md-3 standard-margin-bottom">

            <Button onClick={this.setupPadi} variant="primary" className="col-12 pal-button-blue body-font"> Get Started </Button>
                 
                </div>

                


                </div>

                

        
          
          </div>

      </div>


           {/*----------end of section 1---------*/}


           <div className="row standard-padding-rl grey-bg full-width mOff"> 

           <div className="col-12  standard-padding-rl d-block d-sm-none standard-margin-top">
 <h3 className="blue-text body-font " >  
 
                                       Hey, I’m PADI!
                                       Sticking to your financial
                                       plans for the future
                                       can be difficult, but I am 
                                       here to help!

                           </h3>
                     </div>

             <div className="col-12 col-md-2 text-center"> 
               <img className="standard-margin-top img-fluid padiCircle" src={padiCircle} />
              </div>
              <div className="col-12 col-md-10 standard-margin-bottom standard-margin-top-padi-text">
                     <h3 className="blue-text body-font spaced-text ">
                         It takes just 3 simple steps to make me
                     </h3>
                     <hr className="white-bg mid-width divider-style" />
                     <h3 className="blue-text body-font spaced-text">
                        <span className="lime-text body-font white-text limeBox">your <span className="header-font "> PAL  </span>for life. </span>
                     </h3>

                     <div className="row text-center standard-margin-top"> 
                     <div className="col-12 col-md-4">
                         <figure> 
                             <img className="img-fluid" src={activate} />
                             <figcaption> 
                                 <h3 className="header-font blue-text">
                                     Introduce yourself 
                                 </h3>
                                 <h6 className="body-font blue-text">
                                 Introduce yourself to your PADI by providing basic personal 
                                 information.
                                 </h6>
                             </figcaption>
                         </figure>
                     </div>

                     <div className="col-12 col-md-4">
                         <figure> 
                             <img className="img-fluid" src={lifestyleChoices} />
                             <figcaption> 
                                 <h3 className="header-font blue-text">
                                     Make your lifestyle choices
                                 </h3>
                                 <h6 className="body-font blue-text">
                               Your PADI will walk you through a simple 
                               conversation to understand your current pension
                               contributions and retirement aspirations
                                 </h6>
                             </figcaption>
                         </figure>
                     </div>

                     <div className="col-12 col-md-4">
                         <figure> 
                             <img className="img-fluid" src={financialAdvise} />
                             <figcaption> 
                                 <h3 className="header-font blue-text">
                                     Receive financial insights
                                 </h3>
                                 <h6 className="body-font blue-text">
                               Get an immediate analysis of your contributions against your retirement lifestyle. 
                               Receive a recommendation on how to plan your retirement finances based on your personality test.
                                 </h6>
                             </figcaption>
                         </figure>
                     </div>
                     </div>
                </div>
           
           </div>

    {/*----------end of section 2---------*/}


           <div className="row standard-padding-rl light-blue-bg full-width mOff text-end"> 
        
              <div className="col-12 col-md-9 offset-md-1 standard-margin-bottom standard-margin-top-padi-text text-start">
                     <h3 className="blue-text body-font spaced-text mobileh5">
                        Not sure about your retirement lifestyle choices?
                     </h3>
                     <hr className="white-bg full-width divider-style text-end" />
                     <h3 className="blue-text body-font spaced-text mobileh5">
                     Learn about your  <span className="lime-text body-font white-text limeBox"> financial personality </span>
                     </h3>
                     </div>
                     <div className="col-12 col-md-2 d-none d-md-block"> 
               <img className="standard-margin-top img-fluid" src={padiCircle} width="100%" />
              </div>


              <div className="col-12 standard-margin-bottom text-center">

<Button variant="primary" className="col-9 col-md-2 mx-auto pal-button-blue body-font" id="test"> Take the test </Button>
     
    </div>


                     </div>

          {/*----------end of section 3---------*/}

                     <div className="row standard-padding-rl polka-bg full-width mOff text-center"> 
                     <div className="col-12 standard-margin-top">
                     <h3 className="blue-text header-font spaced-text ">
                        Some beneftis of having a PADI!
                     </h3>
                     </div>

                     <div className="col-12 standard-margin-top text-center"> 
                     <Carousel variant="dark" controls="true" slide="false" >
                       <Carousel.Item>
                              <img
                                className="bino"
                                src={focus}
                                alt="First slide"
                              
                         />
                     <Carousel.Caption>
                           <h1 className="body-text blue-text" > Focus </h1>
                              <p className="body-text blue-text col-12 col-md-5 mx-auto" > Keep your retirement aspirations in view always. Never lose sight of the retirement of your dreams </p>
                     </Carousel.Caption>
                     </Carousel.Item>
                       </Carousel>
                      

                     </div>
                     </div>


          {/*----------end of section 4---------*/}

          <div className="row standard-padding-rl bubble-bg full-width mOff text-center"> 
            <div className="col-12 col-md-6">
            <img src={carBubble} width="33%" className="bubble-car" />

            <img src={houseBubble} width="33%" className="bubble-house" />



            <img src={airplaneBubble} width="33%" className="bubble-travel" />
            </div>
            
           <div className="col-12 col-md-6 burst standard-margin-top"> 
                 <h2 className="body-text blue-text margin-top-bubble"> 
                    Don't let retirement burst your bubble!
                 </h2>

                 <div className="col-12 standard-margin-top"> 
           <Button variant="primary" className="pal-button-blue body-font" id="activate"> Take the test now! </Button>
     
           </div>
           </div>
         
          </div>

      </div>

      <Footer />

      <Modal1 className="modal1" show={this.state.setup}>
        <Modal1.Header>
          <Modal1.Title className="modal1 white-text header-font"> <h1> Tell me about yourself- Personal Information </h1> </Modal1.Title>
          <Button variant="secondary" className="text-end pal-button-blue" onClick={this.closeSetupPadi}>Close</Button>
        </Modal1.Header>
        <Modal1.Body className="modal1 white-text standard-padding-rl">
            <div className="row" id="details-row" >

            <div className="col-12 col-md-6 text-center">

                <div id="fullName">

                <h3 className="header-font white-text"> 
                           Tell PADI your first name
                           </h3>
              
                    
             <Form className="text-center">
             <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" className="form-design-a text-center white-text largeFont fullname" placeholder="" />
                  <Form.Text className="white-text body-font" >
                          We'll never share your personal information with anyone else.
                  </Form.Text>
          
                 
               </Form.Group>
         </Form>
         
        
                        
         <Button variant="primary" className="col-12 col-md-6 pal-button-blue pal-lime-bg body-font" id="submitFullName"> Next </Button>
              
          
                </div>
         
                          
             
                <div id="emailAddy">
             
                           <h3 className="header-font white-text"> 
                           Tell PADI your email address
                           </h3>
              
                 
              <Form className="text-center">
                   <Form.Group className="mb-3" controlId="formBasicEmail">
             
                        <Form.Control type="email" className="form-design-a white-text text-center largeFont" id="email" placeholder="" />
                           <Form.Text className="white-text body-font" >
                                For PAL customers, use your registered email
                           </Form.Text>
                   </Form.Group>
               </Form>


            <Button variant="primary" className="col-12 col-md-5  pal-button-blue pal-lime-bg body-font" id="backToFullname"> Back </Button>

                                 
            <Button variant="primary" className="col-12 offset-md-1 col-md-5  pal-button-blue pal-lime-bg body-font" id="emailSubmission" > Next </Button>
                
                
             </div>

             <div id="gender">
                           <h3 className="header-font white-text"> 
                           Pick a gender
                           </h3>
              

                    <Form.Check >
                    <p className="mOff"> Male </p>
                           <input inline 
                             className="offset-1 offset-md-0 gender"
                             type="radio"
                             id="1"
                             name="gender"
                             label="Male"
                             value="1"
                             row={true}
                             onChange={this.setGenderValue}

                             />
                       
                       <p className="mOff"> Female</p>
                         <input inline 
                             className="offset-1 offset-md-0 gender"
                             type="radio"
                             id="2"
                             name="gender"
                             label="Female"
                             value="2"
                      
                             onChange={this.setGenderValue}

                             />
                         
                               </Form.Check>  
            
                

                        <Button variant="primary" className="col-12 col-md-5 pal-button-blue pal-lime-bg body-font" id="backToMail"> Back </Button>
                                         
            <Button variant="primary" className="col-12 offset-md-1 col-md-5  pal-button-blue pal-lime-bg body-font" id="Submission" > Next </Button>
                
        
                 </div>
                  </div>
           <div className="col-12 col-md-6 pOff mobile-center">

           <img src={padi} className="padi-position-form" width="40%" />

           <div className="col-md-3 text-pane white-bg black-text body-font text-pane-form textform-borderdesign standard-padding-rl">
                           <p className="body-font" id="padi-talk">
                           Hi, I’m PADI!
                                  What's your name?
                               </p>    
                     </div>
    
               </div>
            </div>


            <div className="row text-center setupRow" id="info-row">
                <div className="col-12">
                    <h4 className="header-font white-text"> 
                         Feels like I've known you forever already!                                             
                    </h4>
             < Link to="/Padi/setup/financial-information/contribution">
             <Button onClick="" variant="primary" className="col-12 col-md-2 mx-auto pal-button-blue pal-lime-bg body-font"> Continue  </Button>
                 
             </Link>
          
         
                </div>

                <div className="col-12 col-md-6 mx-auto white-bg">
                <h3 className="body-font black-text standard-margin-top"> Alright, way to go {this.state.firstname}. I am almost ready to analyze your pension contributions and savings. Let's keep going! </h3>
                </div>
               <div className="col-12 mx-auto"> 
               
           <img src={padi} className="padi-position3" width="20%" />
                
            </div>
            </div>
         
            
            </Modal1.Body>
      </Modal1>


      <Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="65%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
   </>


    );

  }
}


export default home