import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import retirementLength from '../images/retirement.jpg'
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import padiCircle from '../images/padi-circle.png';



class retirement extends Component{ 


     
    constructor(props){
        super(props)
        this.state={

           setup:false,
           loader: false
 
        }

        this.submitRetirementYears = this.submitRetirementYears.bind(this)
        this.submissionControl = this.submissionControl.bind(this)

      }
 

      componentDidMount(){
        window.scrollTo(0, 0)
        this.submitRetirementYears()
        this.submissionControl()

      }

      async submissionControl(){

        $('.retirement').change(async function(){ 

           $('.retirementContinue').fadeOut('slow', function(){ 
              $('.retirementSubmission').fadeIn('slow')
           })

        })
   }


      async submitRetirementYears(){ 

        $('.retirementSubmission').click(async function(e){ 

            e.preventDefault()


            if($('.retirement').val() === ""){
              return alert('Please add a value')
            }
            this.setState({
              loader:true
            })
      
          parseInt($('.retirement').val())
            const retirementData = { 
                 yearsToRetirement : parseInt($('.retirement').val())
            }
    
            const pushRetirementYears= await fetch('https://padiserver-70e6153ff382.herokuapp.com/padi/user/financial/data/storeRetirementDetails', { 
                method:"POST",
                headers:{
                  "content-type":"application/JSON"
                },
                body:JSON.stringify(retirementData),
                mode:"cors",
                credentials:'include' 
              })
          
            .then(response => response.json())
            .then(response => { 
               
              this.setState({
                loader:false
              })

                 $('.retirementSubmission').fadeOut('fast', function(){ 

                    $('.retirementContinue').fadeIn('slow')
                 })
    
    
            })

        }.bind(this))

 
      }


      render(){
          return(

            <> 
                  <div className="row standard-margin-top"> 

<div className="col-12 col-md-6 standard-padding-rl"> 

<Badge className="white-text blue-bg" bg="primary "> Your retirement plans </Badge>

 <h3 className="header-font blue-text"> 
 You will retire in how many years? 
 </h3>
 <Form className="text-center standard-margin-top">
    <Form.Group className="mb-3" controlId="">
         <Form.Control type="number" className="form-design-a  blue-text largeFont retirement text-center" placeholder="5" />
            <Form.Text className="text-muted white-text body-font" >
               I'll use this information to advise you on your progress towards your retirement goals.
            </Form.Text>
    </Form.Group>


    <Link to="/Padi/setup/financial-information/aspiration"> 
                     
    <button onClick="" variant="primary" className="col-10 white-text col-md-2 pal-button-blue pal-lime-bg body-font"> Back </button>

    </Link>   
                             
    <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue pal-lime-bg body-font retirementSubmission"> Save </button>

    <Link to="/Padi/setup/lifestyle/choices/housing" className="retirementContinue"> 

               <button onClick="" variant="primary" className="col-10 offset-md-1 white-text col-md-2 pal-button-blue body-font"> Continue  </button>            
                                    
                                      </Link>       
         
</Form>

</div>


<div className="col-12 col-md-6"> 

      <img src={retirementLength} width="100%" /> 
</div>

</div>


<Modal className="padiLoader text-center"
        show={this.state.loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={padiCircle} width="30%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
          <h3 className="header-font white-text">    Updating my memory...  </h3>
        </Modal.Body>
      </Modal>
            </>
          )
      }


}

export default retirement